import React, { useEffect, useRef } from 'react';
import Card from './components/Card';
import CardProject from './components/CardProject';
import Divider from './components/Divider';
import './App.css'; // Ensure the correct relative path for App.css

const LaptopScreenContent = () => {
  const sectionRefs = useRef({});
  const buttonRefs = useRef({});

  useEffect(() => {
    const currentSectionRefs = sectionRefs.current;
    const currentButtonRefs = buttonRefs.current;

    const handleScroll = (entries) => {
      entries.forEach((entry) => {
        const button = currentButtonRefs[entry.target.id];
        if (button) {
          button.classList.toggle('active', entry.isIntersecting);
        }
      });
    };

    const observer = new IntersectionObserver(handleScroll, {
      threshold: 0.5,
    });

    Object.values(currentSectionRefs).forEach((section) => {
      if (section) observer.observe(section);
    });

    return () => {
      Object.values(currentSectionRefs).forEach((section) => {
        if (section) observer.unobserve(section);
      });
    };
  }, []);

  const handleScrollToSection = (sectionId) => {
    if (sectionRefs.current[sectionId]) {
      sectionRefs.current[sectionId].scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="laptop-screen-content">
      {/* Navigation Bar */}
      <div className="nav">
        <div className="nav-content">
        <h1>Lincoln Kness</h1>
        <h2>Software Engineering Student & Aspiring Game Developer</h2>
        <h3>
          I am passionate about creating immersive, engaging, and innovative digital experiences,
          <br /> with a focus on game design, mechanics, and interactive storytelling.
        </h3>
          {/* Navigation Buttons */}
          {['about', 'experience', 'projects'].map((sectionId) => (
            <button
              key={sectionId}
              ref={(el) => (buttonRefs.current[sectionId] = el)}
              onClick={() => handleScrollToSection(sectionId)}
            >
              {sectionId.toUpperCase()}
            </button>
          ))}
        </div>

        {/* Social Media Links */}
        <div className="nav-footer">
          {[
            {
              href: 'https://github.com/LincolnKne',
              imgSrc: '../github.png',
              alt: 'GitHub',
            },
            {
              href: 'https://www.linkedin.com/in/lincolnkness/',
              imgSrc: '../Linkedin.png',
              alt: 'LinkedIn',
            },
            { href: 'mailto:LincolnKness@outlook.com', imgSrc: '../Email.png', alt: 'Email' },
            { href: 'tel:(563)-503-2199', imgSrc: '../Phone.png', alt: 'Phone' },
          ].map((link, index) => (
            <a
              key={index}
              href={link.href}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={link.imgSrc} alt={link.alt} />
            </a>
          ))}
        </div>
      </div>

      {/* Main Content */}
      <div className="content">
        <div className="content-content">
          {/* About Section */}
          <div
            id="about"
            className="section"
            ref={(el) => (sectionRefs.current['about'] = el)}
          >
            <p>
              I am a Software Engineering student at Iowa State University, graduating in May 2025, with a strong passion for game development and software design.
              Initially starting in Aerospace Engineering, I developed foundational skills in <b>Python</b> and <b>MATLAB</b> before pivoting to Software Engineering to pursue my passion for programming and creating interactive experiences.
              <br /> <br />
              Over the course of my studies, I have worked on various projects that span solo development and collaborative team efforts, gaining experience in <b>Java</b>, <b>C/C++</b>, <b>HTML/CSS</b>, <b>JavaScript</b>, <b>React</b>, and <b>Unity</b>. My focus has shifted toward game development, where I combine my technical expertise with creativity to build engaging games and interactive software.
              <br /> <br />
              Recently, I have been diving deeper into non-Euclidean game mechanics and building custom game engines, along with enhancing my skills in <b>graphics programming</b> and <b>AI systems</b>. I am eager to contribute to innovative projects in the gaming industry, leveraging my diverse technical background and passion for creating immersive experiences.
            </p>

          </div>
          <Divider />

          {/* Experience Section */}
          <div
            id="experience"
            className="section"
            ref={(el) => (sectionRefs.current['experience'] = el)}
          >
            <Card
              date="June 2024 — August 2024"
              jobTitle="Information Systems Summer Intern"
              description="Developed and customized a new homepage for Clinton Factory using React and ASP, hosted on IIS. Collaborated with stakeholders to enhance visual appeal and functionality. Implemented automated Snapshot backups using .bat scripts for cluster servers, integrated with Spectrum Backup, ensuring data integrity and availability."
              tags={[
                'JavaScript',
                'HTML',
                'React',
                'IIS Server Configuration',
                '.bat Scripts',
              ]}
              link="https://www.nestlejobs.com/locations/clinton"
            />
          </div>
          <Divider />

          {/* Projects Section */}
          <div
            id="projects"
            className="section"
            ref={(el) => (sectionRefs.current['projects'] = el)}
          >
            {[
              {
                projectTitle: 'Non-Euclidean Game Engine & Game Development',
                description:
                  'Collaborated with a team to design and build a custom non-Euclidean game engine from scratch using C, focusing on innovative spatial mechanics and rendering techniques. Developed a 2D farming game prototype within the engine, featuring non-Euclidean mechanics, light-based gameplay, and suspense elements. My contributions included designing the engine’s core functionality, implementing pathfinding, and integrating graphics and physics systems.',
                imageUrl: '../Game Demo.gif', 
                tags: ['C#', 'Unity'],
                infoLink: '../Faculty-Presentation-sdmay25-37_v3.pptx', // Replace with your file path
                isDownload: true,
              },
              {
                projectTitle: 'Pokemon Roguelike',
                description:
                  'A Pokémon-inspired rogue-like game with procedurally generated terrain and gameplay mechanics, built in C and later ported to C++.',
                imageUrl: '../327PokemonRoguelike.png',
                tags: ['C/C++'],
              },
              {
                projectTitle: 'ISU Marketplace',
                description:
                  'A marketplace application, similar to Etsy, where users can buy and sell unique products. Led a team of four developers, ensuring timely completion of milestones.',
                imageUrl: '../309App.png',
                tags: ['Java', 'Android Studio', 'IntelliJ', 'Git', 'MySQL'],
              },
              {
                projectTitle: 'Nestle Purina NPPC Homepage',
                description:
                  'Redesigned the Clinton Factory homepage with React, focusing on customizability and visual appeal based on departmental needs.',
                imageUrl: '../NPPCLogo.png',
                tags: ['JavaScript', 'React', 'IIS Server'],
              },
              {
                projectTitle: 'Nestle Purina Cluster Server Backups',
                description:
                  'Automated reliable backups for cluster servers using .bat scripts to ensure data integrity.',
                imageUrl: '../NPPCLogo.png',
                tags: ['.bat Scripts', 'Cluster Server'],
              },
            ].map((project, index) => (
              <CardProject key={index} {...project} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LaptopScreenContent;

import React from 'react';
import '../App.css'; // Import the CSS file

const CardProject = ({ projectTitle, description, imageUrl, tags, infoLink, isDownload }) => {
  return (
    <div className="card-project">
      <div className="card-project-content">
        <img src={imageUrl} alt={projectTitle} className="project-image" />
        <div className="project-details">
          <h2 className="project-title">{projectTitle}</h2>
          <p>{description}</p>
          <div className="project-tags">
            {tags.map((tag, index) => (
              <span key={index} className="tag">{tag}</span>
            ))}
          </div>
          {/* Optional Button for More Info or Download */}
          {infoLink && (
            <a
              href={infoLink}
              target={isDownload ? '_self' : '_blank'}
              rel={isDownload ? undefined : 'noopener noreferrer'}
              className="more-info-button"
              download={isDownload ? '' : undefined} // Enables download behavior if true
            >
              {isDownload ? 'Click for More Info' : 'Click for More Info'}
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default CardProject;
